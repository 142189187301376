import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of} from 'rxjs';
import { ILogin, ILoginOTP, ILoginOtpResponse, ISignUpRequest, ISignUpResponse, IVarifyMailResponse, ILoginResponse, IResendOtpRequest, IResendOtpResponse, IVerificatiionCodeResponse, ISetPasswordRequest } from 'src/app/core/models/authentication/request/login.model';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment.development';
import { ROUTES_URL } from 'src/app/constants/routes/routes.constant';
import { API_URL } from 'src/app/constants/API/api-url';
import { CompanyInfoResponse, GstInfoResponse, IAddApi4BusinessDeveloperAppRequest, IAddApi4BusinessDeveloperRequest, IAddApi4BusinessDeveloperResponse, IChangeCurrentCompany, ICreateDeveloperAttributesRequest, IJwtTokenResp, IPanInfoResponse, ISubscribeForProductCamRequest, ISubscribeForProductCamResponse, IUserCompany, IUserProfileImage, IUserProfileResponse } from '../../models/user/user.model';
import { ISupplierToBuyerStatusEnableRequest } from '../../models/handshake/supplierToBuyerHandshake';
import { BASE_URL } from 'src/app/constants/API/microservice-base-url';
import { ENV_VARIABLES } from 'src/app/constants/API/env-variables';
import { ITokenValidityRequest } from '../../models/authentication/jwt/jwt.model';

@Injectable({
  providedIn: 'root'
})
export class LoginServiceService {

  jwtToken: string = localStorage.getItem('jwtToken') || '';

  constructor(private http: HttpClient) {
  }

  signIn(loginObject: ILogin): Observable<ILoginResponse> {
    const encryptedString = CryptoJS.AES.encrypt(JSON.stringify(loginObject).trim(), ENV_VARIABLES.SECRET_KEY.trim()).toString();
    return this.http.post<ILoginResponse>(`${environment.CAM_API_URL}${API_URL.login.login}`, encryptedString);
  }
  loginOtpCheck(loginOtpObject: ILoginOTP): Observable<ILoginOtpResponse> {
    return this.http.post<ILoginOtpResponse>(`${environment.CAM_API_URL}${API_URL.login.loginOtpCheck}`, loginOtpObject);
  }
  signUp(signUpObject: ISignUpRequest): Observable<ISignUpResponse> {
    return this.http.post<ISignUpResponse>(`${environment.CAM_API_URL}${API_URL.login.signUp}`, signUpObject);
  }
  varifyMail(varifyCode: string): Observable<IVarifyMailResponse> {
    return this.http.get<IVarifyMailResponse>(`${environment.CAM_API_URL}${API_URL.login.verifyMail}?verifyCode=${varifyCode}`);
  }
  resendOtp(resendOtpRequest: IResendOtpRequest): Observable<IResendOtpResponse> {
    return this.http.post<IResendOtpResponse>(`${environment.CAM_API_URL}${API_URL.login.resendOtp}`, resendOtpRequest);
  }
  forgotPassword(email: string | null | undefined) {
    const fwdLink = `${environment.UI_BASE_URL}${ROUTES_URL.SET_PASSWORD}`;
    return this.http.get(`${environment.CAM_API_URL}${API_URL.login.forgotPassword}?userName=${email}&productId=${ENV_VARIABLES.PRODUCT_ID}&fwdLink=${fwdLink}`);
  }

  // Verfication API after Forgot Password mail sent
  userVerificationForResetPwd(verificationCode: string): Observable<IVerificatiionCodeResponse> {
    return this.http.get<IVerificatiionCodeResponse>(`${environment.CAM_API_URL}${API_URL.login.checkVerificationCode}?verificationCode=${verificationCode}`);
  }

  // setPassword API
  setPassword(setPasswordObj: ISetPasswordRequest) {
    return this.http.post(`${environment.CAM_API_URL}${API_URL.login.setPassword}`, setPasswordObj);
  }
  enabelSupplierToBuyerConnect(request:ISupplierToBuyerStatusEnableRequest)
  {
    return this.http.post(`${environment.handshakeServiceUrl}${"api/enableSupplierToBuyerHandshake"}`,request);
  }
  getCompanyForUser(userName: string, productId: number) : Observable<CompanyInfoResponse>{
    return this.http.get<CompanyInfoResponse>(`${environment.CAM_API_URL}user/getcompaniesforuserforproduct?userName=${userName}&productId=${productId}`)
  }

  getUserProfile(userId: number): Observable<IUserProfileResponse> {
    return this.http.get<IUserProfileResponse>(`${environment.baseUrl}${API_URL.login.getUserProfile}/${userId}`);
  }

  getUserProfileImage(userName: string): Observable<IUserProfileImage> {
    return this.http.get<IUserProfileImage>(`${environment.baseUrl}${API_URL.login.getUserProfileImage}/${userName}`);
  }

  getAllCompaniesForuser(userName:string): Observable<IUserCompany[]>{
    return this.http.get<IUserCompany[]>(`${environment.baseUrl}${API_URL.login.getAllCompaniesForUser}/${ENV_VARIABLES.PRODUCT_ID}/${userName}`);
  }

  createDeveloper(request: IAddApi4BusinessDeveloperRequest): Observable<IAddApi4BusinessDeveloperResponse> {
    return this.http.post<IAddApi4BusinessDeveloperResponse>(`${environment.APIGEE_MANAGEMENT_SERVICE_BASE_URL}${API_URL.login.developer}?environment=${environment.API4BUSINESS_ENVIRONMENT}`, request);
  }

  createDeveloperApp(request: IAddApi4BusinessDeveloperAppRequest): Observable<IAddApi4BusinessDeveloperResponse> {
    return this.http.post<IAddApi4BusinessDeveloperResponse>(`${environment.APIGEE_MANAGEMENT_SERVICE_BASE_URL}${API_URL.login.developerApp}?environment=${environment.API4BUSINESS_ENVIRONMENT}`, request);
  }

  createDeveloperAppAttributes(request: ICreateDeveloperAttributesRequest): Observable<IAddApi4BusinessDeveloperResponse> {
    return this.http.post<IAddApi4BusinessDeveloperResponse>(`${environment.APIGEE_MANAGEMENT_SERVICE_BASE_URL}${API_URL.login.developerAttributes}?environment=${environment.API4BUSINESS_ENVIRONMENT}`, request);
  }

  subscribeForProductCam(request: ISubscribeForProductCamRequest, userId: number, uuid: string): Observable<ISubscribeForProductCamResponse> {
    let headers = new HttpHeaders();
    headers = headers.set('uuid', uuid);
    return this.http.post<ISubscribeForProductCamResponse>(`${environment.CAM_API_URL}${API_URL.login.subscribeApi4BsinessProduct}?userId=${userId}`, request);
  }

  changeCurrentCompany(changeCurrentCompany: IChangeCurrentCompany): Observable<IJwtTokenResp> {
    return this.http.post<IJwtTokenResp>(`${environment.CAM_API_URL}${API_URL.login.changeCurrentCompany}`, changeCurrentCompany);
  }

  getGstinDetailsFromGstin(gstin: string) : Observable<GstInfoResponse> {
    return this.http.get<GstInfoResponse>(`${environment.MDM_API_URL}${API_URL.login.getGstinDetails}?gstin=${gstin}`);
  }

  checkTokenValidity(tokenValidityCheck:ITokenValidityRequest):Observable<any> 
  {
    return this.http.post(`${environment.CAM_API_URL}${API_URL.login.checkTokenValidity}`,tokenValidityCheck);
  }

  getPanDetails(pan: string) : Observable<IPanInfoResponse> {
    return this.http.get<IPanInfoResponse>(`${environment.MDM_API_URL}${API_URL.login.getPanDetails}?panNumber=${pan}`);
  }
  

}
