import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllowNumbersOnlyDirective } from 'src/app/directives/allow-numbers-only.directive';



@NgModule({
  declarations: [ AllowNumbersOnlyDirective ],
  imports: [
    CommonModule
  ],
  exports:[AllowNumbersOnlyDirective]
})
export class NumbersOnlyModule { }
