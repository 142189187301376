
export const API_URL = {
    login: {
        login: "auth/authenticate/2fa",
        checkTokenValidity:"auth/validateV2",
        loginOtpCheck: 'auth/authenticate/2fa/validate',
        signUp: 'auth/signup',
        checkVerificationCode: 'user/checkverificationcode',
        setPassword: 'auth/setpwd',
        forgotPassword: 'auth/forgotpwdmailV2',
        verifyMail: 'auth/verifymail',
        resendOtp: 'auth/authenticate/2fa/generate',
        getUserProfile: 'api/userProfile',
        getUserProfileImage: 'api/profileImage',
        getAllCompaniesForUser: 'api/allCompaniesForUser',
        developer: 'developer',
        developerApp: 'developer-app',
        developerAttributes: 'v1/developer-attributes',
        subscribeApi4BsinessProduct: '/sub/createForProduct',
        getGstinDetails: 'gstin-master/get-by-gstin',
        getPanDetails: 'gstin-master/get-by-pan',
        changeCurrentCompany: 'auth/validateAndGenerateTokenForAvailableCompany'
    },
    customerMaster: {
        getCustomerMaster: 'api/customer/customer-master?customerMasterId=',
        getApi4BusinessAccountStatus: 'api/company/api4BusinessAccountStatus',
        updateApi4BusinessAccountCreationStatus: 'api/company/updateApi4BusinessAccountStatus/',
        getStateForFilter: 'api/customer/customer-master-filter-state?customerMasterId=',
        getGstinStatusForFilter: 'api/customer/customer-master-filter-gst-status?customerMasterId=',
        getTypeOfCustomerForFilter: 'api/customer/customer-master-filter-customer-type-status?customerMasterId=',
        getHandShakeStatusForFilter: 'api/customer/customer-master-filter-handshake-status?customerMasterId='
    },

    verifyGstin: {
        generateOAuthTokenForApi4Business: "oauth/v1/token",
        generateOTPForGSTINVerification: "gov/v1/otp/generate",
        verifyOTP: "gov/v1/otp/verify",
        getDeveloperAppKey: "developer-app-keys",
    },

    invoice: {
        getPO: 'api/invoice/allPos',
        getDocumentType: 'api/invoice/documentTypes',
        getAllCustomers: 'api/invoice/allCustomers',
        invoiceUpload: 'api/invoice/upload',
        invoiceDetails: 'api/invoice/invoiceDetails',
        getAllInvoices: 'api/invoice/allInvoices',
        getCompanyDetails: 'api/invoice/companyDetails',
        getInvoiceAndSupportingDocuments: 'api/invoice/getInvoiceAndSupportingDocuments',
        getHeaderDetails: 'api/invoice/headerDetails/api4BusinessSupplier',
        getPort: 'api/invoice/ports',
        getLineItems: 'api/invoice/lineItems',
        deleteLineItem: 'api/invoice/lineItem',
        syncInvoiceDataFromGov: 'api/invoice/api4BusinessSupplier/supplierIrnList',
        eInvoiceApplicabilityFromGov: 'vas-api/e-invoice-applicability-v1',
        eInvoiceApplicabilityFromDB: 'api/eInvoiceStatus',
        updateEInvoiceApplicabilityFromDB: 'api/updateEInvoiceApplicableStatus',
        checkUniqueInvoiceNumber: 'api/invoice/checkUniqueInvoiceNumber',
        getEinvoiceByInvoiceId: 'api/invoice/api4BusinessSupplier/getEInvoiceByInvoiceId/',
        getDocumentCategory: 'api/invoice/supportingDocument/categories',
        postSupportingDocument: 'api/invoice/supportingDocuments',
        deleteSupportingDocument: "api/invoice/supportingDocument",
        addLineItems: "api/invoice/lineItems",
        sendInvEmailToBuyer: 'api/invoice/sendInvoiceToBuyer',
        getSyncInvoiceProgressStatus: 'api/invoice-history/sync-data-progress-status',
        getSyncInvoiceHistoryData: 'api/invoice/history/getHistory',
        getInvoiceHistorySingleRecord: 'api/invoice/history/getInvoiceDetailsHistory',
        getFilingStatus: 'api/invoice/filingStatus/api4BusinessSupplier/getFilingStatus',
        getBuyerContactPersonList: 'api/invoice/buyer_contact_person/getBuyerContactPerson'
    },
    common: {
        getStates: 'api/invoice/states',
        getCountries: 'api/invoice/countries',
    },
    dashboard: {
        getDashboard: 'api/dashboard',
        getTotalCustomers: 'api/dashboard/totalCustomers',
        getTotalInvoiceCount: 'api/dashboard/totalInvoiceCount',
        getTotalInvoiceAmount: 'api/dashboard/totalInvoiceAmount',
        getTotalPOInvoiceCount: 'api/dashboard/totalPOInvoiceCount',

        getTotalInvoiceWidget: 'api/dashboard/totalInvoiceWidget',
        getAllCustomerName: 'api/customer/getAllBuyerNames',
        getAllCustomerStateWithGstin: 'api/customer/getAllCustomerStatewithGstin',
        getFinacialYear: "api/dashboard/getFinancialYear",
        getMonth: "api/dashboard/getFinancialMonth",
        getInvoiceStatus: 'api/dashboard/getInvoiceStatus',
        getInvoiceSource: 'api/dashboard/getInvoiceSourceCount',
    },
    userInvite: {
        userInvite: "user/inviteuserV2",
        acceptInvite: "user/acceptinviteV2",
        userInviteSupplierDB: "api/inviteUser",
        inviteUserToParentCompany: "api/inviteUserToParentCompany",
        getAllUserQeryParam: "user/getUserRoleBySearch?companyId=",
        checkUserExists: "api/checkUserExists",
    },
    company: {
        updateSupplierPreference: "api/SupplierInvoicePreferenceData",
        getSupplierPreference: "api/getSupplierPreference",
        getGovUsernameByGstin: "api/company/govUsernameByGstin",
        updateGovUsernameBySuppCompId: "api/company/updateGovUsernameByCompId",
        getCompanysByCompanyId: "api/company/allCompanysByCompany/",
        getGstinDetailsFromMDM: "api/company/getCompanyDetailsFromMdm",
        getCompanyUploadHistory: "api/customerUploadHistory/getCustomerUploadHistory",
        getCustomerUploadDetailsbyId: "api/customerUploadHistory/getCustomerDetailsHistory/",
        updateCustomerRequest: "api/updateCompany",
        syncGstin: "api/company/getCompanysFromPan",
        getBuyerList: "api/customer/getAllBuyerNameList",
    },
    handshake:{
        initiatehandshake:"/handshake/initiatehandshake",
        updatedHandshakeStatus:"api/getHandshakesByPan",
        acceptHandshake:"/handshake/verifyhandshake?handshakeVerificationCode=",
        insertUpdateHandshakeData:"api/handshake/insertUpdateHandshakeData",
        getEmailDeliveryStatus: "api/handshake/getEmailDeliveryStatus"
    }
}