import { Injectable } from '@angular/core';
import { IHandshakeInitiateResponse, IHandshakeRequest, ISupplierBuyerHandShakeRequest, ISupplierBuyerHandshakeResponse } from '../../models/handshake/supplierToBuyerHandshake';
import { Observable } from 'rxjs';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment.development';
import { API_URL } from 'src/app/constants/API/api-url';

@Injectable({
  providedIn: 'root'
})
export class HandshakeService {

  constructor(private http:HttpClient) { }

  handShakeRequestToBuyer(handshakeRequest: ISupplierBuyerHandShakeRequest): Observable<ISupplierBuyerHandshakeResponse> {
    return this.http.post<ISupplierBuyerHandshakeResponse>(`${environment.handshakeServiceUrl}${"api/supplierToBuyerHandshake"}`,handshakeRequest);
  }
  initiateHandShakeRequestToBuyer(handshakeRequest:IHandshakeRequest)
  {
    return this.http.post<IHandshakeInitiateResponse>(`${environment.HANDSHAKE_URL}${API_URL.handshake.initiatehandshake}`,handshakeRequest);
  }

  getUpdatedHandshakeStatus(panNumber:string)
  {
    return this.http.get(`${environment.handshakeServiceUrl}${API_URL.handshake.updatedHandshakeStatus}${"?pan="}${panNumber}`);
  }
  acceptInvitationRequest(verificationCode:string)
  {
    return this.http.put(`${environment.HANDSHAKE_URL}${API_URL.handshake.acceptHandshake}${verificationCode}`,{});
  }
  insertUpdateHandshakeData(request:any)
  {
    return this.http.post(`${environment.handshakeServiceUrl}${API_URL.handshake.insertUpdateHandshakeData}`,request);
  }

  getEmailDeliveryStatus(initiatorPan: String, receiverPan: String) {
    return this.http.get(`${environment.handshakeServiceUrl}${API_URL.handshake.getEmailDeliveryStatus}${"?initiatorPan="}${initiatorPan}${"&receiverPan="}${receiverPan}`);
  }
}
