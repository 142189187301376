import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from 'src/environments/environment.development';
import { API_URL } from 'src/app/constants/API/api-url';
import { IAuthTokenResponse, IDeveloperAppKeyResponse } from '../../models/gst-verification/generate-auth-token';
import { IGenerateOTPRequest, IGenerateOTPResponse, IGetGovRegUsernameByCompResponse, IUpdateGovRegUsernameByCompRequest, IVerifyOTPResponse } from '../../models/gst-verification/otp-verification';
import { Observable, catchError, map, switchMap, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BASE_URL } from 'src/app/constants/API/microservice-base-url';
import { LoginServiceService } from './login-service.service';
import { ENV_VARIABLES } from 'src/app/constants/API/env-variables';
 
@Injectable({
  providedIn: 'root'
})
export class GstVerificationService {
 
  jwtToken !: string | null;
  clientId !: string | null;
  clientSecret !: string | null;
 
  toast: ToastrService = inject(ToastrService);
 
  constructor(private http: HttpClient) {
    this.jwtToken = localStorage.getItem("jwtToken");
  }
 
  generateOauthTokenForApi4Business(): Observable<IAuthTokenResponse> {
    return this.getClientIdAndSecretApi().pipe(
      switchMap((resp: IDeveloperAppKeyResponse) => {
                  this.clientId = resp.consumerKey;
          this.clientSecret = resp.consumerSecret;
          // this.toast.info("Client Id and Secret generated");
 
          const headers = new HttpHeaders({
              'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization': 'Basic ' + btoa(`${this.clientId}:${this.clientSecret}`)
          });
 
          const urlencoded = new URLSearchParams();
          urlencoded.append("grant_type", "client_credentials");
 
          return this.http.post<IAuthTokenResponse>(`${environment.API4BUSINESS_URL}${API_URL.verifyGstin.generateOAuthTokenForApi4Business}`, urlencoded.toString(), { headers });
      }),
      catchError((error: any) => {
          this.toast.error("Cannot get Client Id and Secret Key");
          // Return an observable that errors out
          return throwError(() => new Error("Cannot get Client Id and Secret Key"));
      })
  );
  }
 
  getClientIdAndSecretApi(): Observable<IDeveloperAppKeyResponse>{
    // need to pass the token
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + this.jwtToken
    });
    return this.http.get<IDeveloperAppKeyResponse>(`${environment.APIGEE_MANAGEMENT_SERVICE_BASE_URL}${API_URL.verifyGstin.getDeveloperAppKey}?environment=${environment.API4BUSINESS_ENVIRONMENT}`, {headers: headers});
  }
 
  generateOtpForGSTINVerification(generateOTPRequest: IGenerateOTPRequest) {
    const header = new HttpHeaders({
      gstin: generateOTPRequest.gstin != null ? generateOTPRequest.gstin : ''
    })
    return this.http.post<IGenerateOTPResponse>(`${environment.API4BUSINESS_URL}${API_URL.verifyGstin.generateOTPForGSTINVerification}`,{}, {headers: header});
  }
 
  verifyOTP(otp: string, gstin: string | null) {
    const body = {
      "otp": otp
    };
   
    return this.http.post<IVerifyOTPResponse>(
      `${environment.API4BUSINESS_URL}${API_URL.verifyGstin.verifyOTP}`,
      body, // No need to stringify
      { headers: { 'Content-Type': 'application/json',  gstin: gstin != null ? gstin : ''}} // This is typically unnecessary unless you need to override for some reason
    );
  }

  getGovUsernameByGstin(gstin: string | null): Observable<IGetGovRegUsernameByCompResponse>{
    return this.http.get<IGetGovRegUsernameByCompResponse>(`${environment.customerUrl}${API_URL.company.getGovUsernameByGstin}/${gstin}`);
  }

  updateGovUsernameByCompId(updateGovUsernameBody: IUpdateGovRegUsernameByCompRequest): Observable<IGetGovRegUsernameByCompResponse>{
    return this.http.patch<IGetGovRegUsernameByCompResponse>(`${environment.customerUrl}${API_URL.company.updateGovUsernameBySuppCompId}`, updateGovUsernameBody);
  }
}