import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.development';
import { IUserCompanyResponse } from '../../models/customer-master/customer-master.model';
import { IAggregateDetails, IUserExistsResponse, IUserInformation, IUserInviteResponse, IUserMasterRequest, IUserRequest, IUserResponse } from '../../models/user/user.model';
import { API_URL } from 'src/app/constants/API/api-url';
import { BASE_URL } from 'src/app/constants/API/microservice-base-url';
import { ENV_VARIABLES } from 'src/app/constants/API/env-variables';
import { UUIDGenerator } from '../../utils/UUIDGenerator';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private httpClient:HttpClient) { }

  getCompaniesByUser(userEmailId:String, camCompanyId: number, uuid: string | null) {
    let headers = new HttpHeaders();
    headers = headers.set('uuid', uuid ? uuid : UUIDGenerator.generateUUID());
    return this.httpClient.get<IUserCompanyResponse>(`${environment.customerUrl}${"api/getCompanyByUser/"}${userEmailId}/${camCompanyId}`, { headers: headers });
  }
  onBoardUserWithCompanyMaster(userRequest:IAggregateDetails)
  {
    return this.httpClient.post(`${environment.customerUrl}${"api/addUser"}`,userRequest);
  }
  userInvites(userInviteRequest:IUserInformation)
  {
    return this.httpClient.post<IUserInviteResponse>(`${environment.CAM_API_URL}${API_URL.userInvite.userInvite}`, userInviteRequest)
  }
  acceptInvite(verificationCode:string)
  {
    return this.httpClient.get(`${environment.CAM_API_URL}${API_URL.userInvite.acceptInvite}${"?verificationCode="}${verificationCode}`);
  }
  inviteUserToSupplierDB(userRequest:IUserRequest)
  {
    return this.httpClient.post(`${environment.account}${API_URL.userInvite.userInviteSupplierDB}`,userRequest);;
  }
  inviteUserToParentCompany(parentUserCompanyId:string,invitedUserEmail:string)
  {
    //http://localhost:8080/api/inviteUserToParentCompany?parentUserCompanyId=303&invitedUserEmail=pratik.patil@iqdigi.com
    return this.httpClient.get(`${environment.account}${API_URL.userInvite.inviteUserToParentCompany}${"?parentUserCompanyId="}${parentUserCompanyId}${"&invitedUserEmail="}${invitedUserEmail}`);
  }
  getUserMasterData(userRequest:IUserMasterRequest,companyId:string,)
  {
    return this.httpClient.post<IUserResponse>(`${environment.CAM_API_URL}${API_URL.userInvite.getAllUserQeryParam}${companyId}${"&productId="}${ENV_VARIABLES.PRODUCT_ID}`,userRequest);
  }
  checkUserExistsOrNot(emailId:string)
  {
    return this.httpClient.get<IUserExistsResponse>(`${environment.account}${API_URL.userInvite.checkUserExists}/${emailId}`);
  }
}
