import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IndianCurrencyFormatService {
  setCurrencyType$: BehaviorSubject<string> = new BehaviorSubject('None');
  convertToIndianCurrencyFormat(value: number): string {
    const currencyFormatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 2,
    });

    return currencyFormatter.format(value);
  }

  convertToCurrencyWOSymbol(value: number): string {
    const currencyFormatter = new Intl.NumberFormat('en-IN', {
      style: 'decimal',
      minimumFractionDigits: 0,
    });

    return currencyFormatter.format(value);
  }

  currencyFormatterChart(value: any): string {
    const type = this.setCurrencyType$.getValue();
    const cleanedValue = value ? value?.toString()?.replace(/₹|,/g, '') : 0;
    const numericValue = cleanedValue;

    let ret = '0';

    if (type === 'None') {
      return this.convertToIndianCurrencyFormat(numericValue);
    } else if (type === 'Lakhs') {
      ret = (numericValue / 100000).toFixed(2);
    } else if (type === 'Crore') {
      ret = (numericValue / 10000000).toFixed(2);
    } else if (type === 'Millions') {
      ret = (numericValue / 1000000).toFixed(2);
    } else {
      ret = numericValue;
    }

    return ret;
  }

 
  getCurrencyTypes(): string {
    const currency = this.setCurrencyType$.getValue();
    if (currency != null && currency != undefined) {
      switch (currency) {
        case "Lakhs":
          return " L";
        case "Crore":
          return " Cr";
        case "Millions":
          return " M";
        default:
          return "";
      }
    }
    return "";
  }
}
