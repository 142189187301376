import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private _breadcrumbSubject: BehaviorSubject<{ title: string, link: string, isActive: boolean }[]> = new BehaviorSubject<{ title: string, link: string, isActive: boolean }[]>([]);
  breadcrumb$ = this._breadcrumbSubject.asObservable();
  setBreadcrumb(breadcrumb: { title: string, link: string, isActive: boolean }[]) {
    this._breadcrumbSubject.next(breadcrumb);
  }

  clearBreadcrumb(): void {
    this._breadcrumbSubject.next([]);
  }




}
