export const ROUTES_URL = {
    SIGN_IN: '',
    SIGN_UP: '/signUp',
    LOGIN_OTP: '/login-otp',
    FORGOT_PASSWORD: '/forgot-password',
    SET_PASSWORD: '/set-password',
    DASHBOARD: '/app',
    RECEIVABLE: '/app/receivable',
    SETTINGS: '/app/settings',
    INVOICE: '/app/receivable/invoice',
    CUSTOMER: '/app/receivable/customer',
    PURCHASE: '/app/receivable/purchase-order',
}