export const ENV_VARIABLES = {
    API4BUSINESS_PRODUCT_ID: 26,
    API4BUSINESS_PLAN_ID: 61,
    ENTITY_TYPE:"7",
    SUPPLY_TYPE: 'B2B',
    RTN_PRD: '032024',
    DEFAULT_ROLE_ADMIN:'81',
    API4BUSINESS_TG_USERNAME: "HZeyFKpAZia7C1tD9fRua4G0ZRrrVUf8AfscnPbRxHt4t8vi",
    API4BUSINESS_TG_PASSWORD: "JS5cFNmkTFVCkxwi0JHPvBFl5nfmOAW5dHYRQjyERvEPBqVtEgGUDG48Xv6DGqxV",
    invoices:{
        handshakedCustomerInvoices:'050c1c0d-7c53-4864-9b8c-2a62df1f1b52',
        allInvoice:'32fd4864-0c06-42d4-be69-e2ca5265a26d'
    },
    localhostUrl: 'http://localhost:8080/',
    ROLE_ID_USER:81,
    PLAN_ID_DEFAULT:51,
    PLAN_VARIANT_ID:1,
    PRODUCT_ID:"25",
    FWD_LINK:"",
    SECRET_KEY:'TaxGenie2022@!@#',
    INVOICE_SYNC_ATTEMPTS_IF_FILING_STATUS_DONE: 5,
    INVOICE_SYNC_ATTEMPTS_IF_INVALID_CREDS: 2,
    LOGIN_OTP_TIMER: 60,
    LOGIN_OTP_VERIFY_TIME: 900, // 15 minutes (CAM)
    VERIFY_GSTIN_OTP_VERIFY_TIME: 900
}