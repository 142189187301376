import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appAllowNumbersOnly]'
})
export class AllowNumbersOnlyDirective {
  constructor(private _el: ElementRef) {}

  @Input('allowZero') allowZero = true;
  @HostListener('input', ['$event']) onInputChange(event: { stopPropagation: () => void; }) {
    let initalValue = this._el.nativeElement.value;
    initalValue = initalValue.replace(/[^0-9]*/g, '');
    if(!this.allowZero)
      initalValue = initalValue.replace(/^0+/, '');
    this._el.nativeElement.value = initalValue;
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
