import { IBuyerListDataResponse, ICustomerExcelUploadRequest, ICustomerHistoryDataResponse, ICustomerSingleHistoryDataResponse, ICustomerSyncHistoryDataRequest, ISyncGstin } from '../../models/customer/customer.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.development';
import { ICompanysByCompanyIdBaseResp, ICustomerExcelResponse, ICustomerMasterRequest, ICustomerMasterResponse, IGstinStatusFilterData, IHandshakeStatusFilterData, IIsApi4BusinessAccountCreatedStatusResponse, IStateFilterData, ITypeOfCustomerFilterData, IGetGstinDetailsFromMDMBaseResp, ICompanyRequest } from '../../models/customer-master/customer-master.model';
import { API_URL } from 'src/app/constants/API/api-url';
import { BASE_URL } from 'src/app/constants/API/microservice-base-url';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) { }

  uploadCustomerExcel(customerExcelUploadRequest: ICustomerExcelUploadRequest) {
    const formData: FormData = new FormData();
    formData.append('customerExcel', customerExcelUploadRequest.customerExcel);
    formData.append('supplierGstIn', customerExcelUploadRequest.supplierGstIn);
    formData.append('updatedBy', localStorage.getItem("username") || "");
    return this.http.post<ICustomerExcelResponse>(`${environment.customerUrl}${"api/getExcelOfCustomer"}`, formData);
  }
  private httpClient: HttpClient = inject(HttpClient);
  private jwtToken: string = localStorage.getItem('jwtToken') as string;

  getCustomerMasterData(customerMasterRequest: ICustomerMasterRequest,customerMasterId:string): Observable<ICustomerMasterResponse> {
    return this.httpClient.post<ICustomerMasterResponse>(`${environment.customerUrl}${API_URL.customerMaster.getCustomerMaster}${customerMasterId}`, customerMasterRequest);
  }

  getIsApi4BusinessAccountCreatedStatus(suppCompId: number, username: string, uuid: string): Observable<IIsApi4BusinessAccountCreatedStatusResponse> {
    let headers = new HttpHeaders();
    headers = headers.set('uuid', uuid);
    return this.http.get<IIsApi4BusinessAccountCreatedStatusResponse>(`${environment.customerUrl}${API_URL.customerMaster.getApi4BusinessAccountStatus}/${suppCompId}/${username}`, { headers: headers });
  }

  updateApi4BusinessAccountCreationStatus(panNumber: string | null, camCompanyId: number | null): Observable<any> {
    const headers = new HttpHeaders({
      camCompanyId: camCompanyId != null ? camCompanyId : ''
    })
    return this.http.post<any>(`${environment.customerUrl}${API_URL.customerMaster.updateApi4BusinessAccountCreationStatus}${panNumber}`, null, { headers: headers });
  }

  getCompanysByCompanyId(companyId: number | null): Observable<ICompanysByCompanyIdBaseResp> {
    return this.http.get<ICompanysByCompanyIdBaseResp>(`${environment.customerUrl}${API_URL.company.getCompanysByCompanyId}${companyId}`);
  }

  getGstinDetailsFromMdm(gstin: string | null, compMasterId: number | null): Observable<IGetGstinDetailsFromMDMBaseResp> {
    return this.http.get<IGetGstinDetailsFromMDMBaseResp>(`${environment.customerUrl}${API_URL.company.getGstinDetailsFromMDM}?gstin=${gstin}&companyMasterId=${compMasterId}`);
  }

  getStateForFilter(request: ICustomerMasterRequest,customerMasterId:string): Observable<IStateFilterData> {
    return this.http.post<IStateFilterData>(`${environment.customerUrl}${API_URL.customerMaster.getStateForFilter}${customerMasterId}`, request);
  }
  getGstinStatusForFilter(request: ICustomerMasterRequest,customerMasterId:string): Observable<IGstinStatusFilterData> {
    return this.http.post<IGstinStatusFilterData>(`${environment.customerUrl}${API_URL.customerMaster.getGstinStatusForFilter}${customerMasterId}`, request);
  }
  getTypeOfCustomerForFilter(request: ICustomerMasterRequest,customerMasterId:string): Observable<ITypeOfCustomerFilterData> {
    return this.http.post<ITypeOfCustomerFilterData>(`${environment.customerUrl}${API_URL.customerMaster.getTypeOfCustomerForFilter}${customerMasterId}`, request);
  }
  getHandShakeStatusForFilter(request: ICustomerMasterRequest,customerMasterId:string): Observable<IHandshakeStatusFilterData> {
    return this.http.post<IHandshakeStatusFilterData>(`${environment.customerUrl}${API_URL.customerMaster.getHandShakeStatusForFilter}${customerMasterId}`, request);
  }

  getCustomerUploadHistory(request: ICustomerSyncHistoryDataRequest): Observable<ICustomerHistoryDataResponse> {
    return this.http.post<ICustomerHistoryDataResponse>(`${environment.customerUrl}${API_URL.company.getCompanyUploadHistory}`, request);
  }

  getCustomerHistorySingleRecord(customerUploadId: number): Observable<ICustomerSingleHistoryDataResponse> {
    return this.http.get<ICustomerSingleHistoryDataResponse>(`${environment.customerUrl}${API_URL.company.getCustomerUploadDetailsbyId}${customerUploadId}`);
  }
  updateCustomerRequest(rqeuest:ICompanyRequest,supplierId: any,username:any){
    return this.http.post(`${environment.customerUrl}${API_URL.company.updateCustomerRequest}/${supplierId}/${username}`,rqeuest);
  }

  getBuyerList(supplierCompanyMasterId: any)
  {
    return this.http.get<IBuyerListDataResponse>(`${environment.customerUrl}${API_URL.company.getBuyerList}/${supplierCompanyMasterId}`);
  }

  syncGstin(pan: string | null): Observable<ISyncGstin> {
    return this.http.get<ISyncGstin>(`${environment.customerUrl}${API_URL.company.syncGstin}?pan=${pan}`);
  }
}
